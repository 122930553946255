.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    position: relative;
    min-height: 1px;
    outline: none;

    & > a {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}


.slick-arrow {
    background: rgba(255, 255, 255, 0.55);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: block;
    position: absolute;
    margin: -38px 0 0 0;
    outline: none;
    top: 50%;
    height: 46px;
    text-indent: -9999px;
    width: 46px;
    z-index: 2;
}


.slick-vertical {
    .slick-arrow {
        left: 50%;
        margin: 0 0 0 -14px;
        right: auto;
    }

    .slick-prev {
        top: auto;
        bottom: -30px;

        &:before {
            margin: -8px 0 0 -3px;
            transform: rotate(-90deg);
        }
    }

    .slick-next {
        top: -30px;

        &:before {
            margin: -9px 0 0 -3px;
            transform: rotate(-90deg);
        }
    }
}


.slick-prev {
    left: 1em;

    &:before {
        background: url(/assets/images/arrow-prev.svg) center no-repeat;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -11px 0 0 -11px;
        height: 22px;
        width: 22px;
        text-indent: 0;
    }
}


.slick-next {
    right: 1em;

    &:before {
        background: url(/assets/images/arrow-next.svg) center no-repeat;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -11px 0 0 -11px;
        height: 22px;
        width: 22px;
        text-indent: 0;
    }
}



.slick-dots {
    background: rgba(174,51,79,0.42);
    border-radius: 2px;
    display: block;
    list-style: none;
    padding: 0 1em;
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    text-align: center;
    z-index: 2;

    & > li {
        display: inline-block;
        height: auto;
        width: auto;

        & > button {
            background: none;
            border: none;
            border-radius: 50%;
            cursor: default;
            outline: none;
            position: relative;
            height: 37px;
            text-indent: -9999px;
            width: 37px;

            &:after {
                background: #fff;
                border-radius: 50%;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -4px 0 0 -4px;
                height: 9px;
                width: 9px;
            }

            &:before {
                background: transparent;
                border: 1px solid transparent;
                border-radius: 50%;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -10px 0 0 -10px;
                height: 19px;
                width: 19px;
            }
        }

        &:not(.slick-active) {
            & > button {
                cursor: pointer;
            }
        }

        &.slick-active {

            & > button {
                pointer-events: none;
                -webkit-tap-highlight-color: transparent;

                &:before {
                    border-color: #fff;
                }
            }
        }
    }
}

