@charset "UTF-8";


@import "slick/slick";
@import "slider";
@import "indx";

//@import "articles/articles";

//@import "photo/photos";
//@import "qa";
//@import "messages";


/*
@import "news/news";
@import "news/newsfull";
@import "news/navigation";
@import "news/news-cats";*/
