@import "vars";
@import "media";


// Стили слайдера на главной
.slider-block {
	overflow: hidden;
	padding: 0 0 37px;
	position: relative;
	height: 294px;
	width: 100%;

	@include media(xlg) {
		height: 256px;
	}

	@include media(lg) {
		height: 256px;
	}

	@include media(slg) {
		height: 200px;
	}

	@include media(md) {
		height: 150px;
	}

	@include media(xmd) {
		height: 190px;
	}

	@include media(570px) {
		height: 215px;
	}

	@include media(540px) {
		height: 235px;
	}

	@include media(xs) {
		height: 220px;
	}

	@include media(350px) {
		height: 205px;
	}

	.slider,
	.slick-list,
	.slick-track {
		height: 100%;
	}


	.slick-slider {
		z-index: 2;
	}

	.slick-dots {
		background: none;
		bottom: -37px;

		& > li {
			&.slick-active > button:before {
				border-color: #504d81;
			}

			& > button {
				height: 32px;
				width: 32px;

				&:before {

				}

				&:after {
					background: #504d81;
				}
			}
		}
	}
}



.mobile {
	.slider-block {
	}
}

.no-mobile {
	
	.slider-block {
		.slick-arrow {
			&:hover {
				background: none;
			}
		}
	}

	.slider-item {
		&__label {

			.button-block {
				& > .button {
					&:hover {
						border-color: $buttonBgColor1--hover;
						background: $buttonBgColor1--hover;
						color: #fff;
					}
				}
			}
		}
	}
}



.slider-item {

	&__wrapper {
		padding: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 60%;
		z-index: 2;

		&.right {
			left: 0;
			right: auto;
		}

		@include media(xmd) {
			height: 140px;
			top: auto;
			width: 70%;
		}

		@include media(570px) {
			width: 80%;
		}

		@include media(540px) {
			height: 140px;
			width: 100%;
		}

		@include media(xs) {
			height: 125px;
		}

		@include media(350px) {
			height: 110px;
		}
	}

	/*&__video {
		overflow: hidden;
		height: 100%;

		& > video {
			display: block;
			width: 100%;
		}
	}*/

	&__label-wrapper {
		left: 0;
		padding: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 40%;
		z-index: 3;

		&.right {
			left: auto;
			right: 0;
			text-align: right;

			@include media(540px) {
				left: 0;
				right: auto;
				text-align: left;
			}
		}

		@include media(xmd) {
			bottom: auto;
			top: 16px;
			width: 250px;
		}

		@include media(540px) {

		}

		@include media(sm) {

		}
	}

	&__label {
		align-content: center;
		display: none;
		flex-wrap: wrap;
		max-width: 890px;
		margin: 0 auto;
		height: 100%;
		overflow: hidden;
		transition-duration: 0s;
		transition-delay: 0s;

		&-desc {
			font-weight: bold;
			font-size: 47px;
			line-height: 106%;
			color: #262262;
			width: 100%;

			& > span {
				color: $pink;
				display: block;
			}

			@include media(xlg) {
				font-size: 42px;
			}

			@include media(lg) {
				font-size: 36px;
			}

			@include media(slg) {
				font-size: 30px;
			}

			@include media(md) {
				font-size: 26px;
			}

			@include media(xmd) {
				font-size: 23px;
			}


		}

		&-title {
			font-weight: normal;
			font-size: 20px;
			line-height: 108%;
			color: #504D81;
			margin: 0 0 15px;
			width: 100%;

			@include media(xlg) {

			}

			@include media(lg) {
				font-size: 19px;
			}

			@include media(slg) {
				font-size: 16px;
			}

			@include media(md) {
				font-size: 15px;
			}

			@include media(xmd) {
				font-size: 14px;
			}

		}

		.button-block {
			margin: 1em 0 0;
			text-align: center;

			& > .button {
				background: $white;
				border-color: $white;
				color: #48484e;
				font-weight: 600;
				display: inline-block;
			}
		}
	}

	&.slick-active {

		.slider-item__label {
			display: block;
			display: flex;
			transition: 0.5s ease-out;
		}
	}
}


