$containerWidth: 1440px;
$mobileFixedHeaderHeight: 38px;
$desktopFixedHeaderHeight: 30px;

$isAdaptive: true;

$fontName: "GTEestiPro";
$fontSizeBase: 20px;

$white: #fff;
$black: #444;

$linkColor: #ca304f;             /* Цвет ссылок */
$linkColor--hover: #ac8956;      /* Цвет ссылок при наведении */
$linkColor--hover2: #f6846a;

$webkitHighlightTapColor: rgba(0,0,0, 0.15);


$textColor: $black;                           /* Цвет текста */
$grey: #f1f1f1;
$pink: #ef559e;
$green: #9ed178;
$lightGreen: #9ed178;
$lightGrey: #f1f3f8;
$violet: #cf98de;
$sand: #f6846a;
$transSand: rgba(246,132,106,0.8);
$lightGrey: #f2f2f2;
$transGrey: rgba(255,255,255,0.8);
$blue: #67b0d6;
$lightBlue: #bae4f9;

$iconColor: $linkColor--hover;

$bgColor: #ffffff;                        /* Цвет фона */
$headerTopBgColor: #f1f1f1;             /* Цвет фона верхней шапки */
$headerTopBgColorMobile: #fff;         /* Цвет фона верхней шапки на мобильных */
$headerBottomBgColor: #fff;            /* Цвет фона нижней шапки  */


$footerTopBgColor: #2b2e35;
$footerBottomBgColor: #2b2e35;
$footerLinkColor: #fff;


/* Цвета ошибок */
$colorError: rgba(253, 31, 36, 0.7);

/* Цвета ОК */
$colorOk: rgba(92, 253, 5, 0.7);


/* Borders */
$borderColor: #ccc;
$borderRadius: 6px;               /* Закругления */
$photoBorderRadius: 0;
$border: 1px solid $borderColor;

/* Вертикальный градиент вверх */
@mixin gradient($startColor, $endColor, $gradiend) {

  @if($gradiend == false) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}


/* Кнопки */
$isButtonGradient: false;                /* Градиент да/нет? Если нет, используется цвет 1 */
$isButtonShadow: false;                 /* Тень, да/нет          */
$isButtonBorder: true;                 /* Рамка, да/нет          */

$buttonBgColor1: $linkColor;                 /* Цвет фона 1 (нижний)  */
$buttonBgColor2: $linkColor;                 /* Цвет фона 2 (верхний) */
$buttonBorder: 1px solid transparent;        /* Рамка кнопок          */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;   /* Тень у кнопок         */
$buttonColor: #fff;                     /* Цвет текста кнопок    */
$buttonBorderRadius: $borderRadius;     /* Закругления           */
$buttonBorderColor: $blue;


// наведение
$buttonBgColor1--hover: #9a82b1;
$buttonBgColor2--hover: #9a82b1;
$buttonColor--hover: #fff;
$buttonBorderColor--hover: #9a82b1;
$buttonShadow--hover: none;


// актив
$buttonBgColor1--active: #d16961;
$buttonBgColor2--active: #d16961;
$buttonColor--active: #fff;
$buttonBorderColor--active: #1b9fae;
$buttonShadow--active: none;


@mixin button() {

  background: linear-gradient(180deg, #504D81 0%, #262262 100%);
  border-radius: 15px;

  &.disabled {
    background: #aaa;
    cursor: default;
    pointer-events: none;
  }

  border: $buttonBorder;

  &.--inverse {
    background: $buttonColor;
    border: 1px solid rgba(42, 39, 102, 0.5);
    color: #000;
  }

  &.big {
    border: 1px solid rgba(42, 39, 102, 0.5);
    border-radius: 46px;
    box-shadow: inset 0 0 0 10px rgb(255,255,255);
    font-size: 17px;
    height: 75px;
    line-height: 74px;
    min-width: 260px;

    @include media(xlg) {
      border-radius: 36px;
      height: 70px;
      line-height: 69px;
      min-width: 240px;
    }

    @include media(lg) {
      border-radius: 32px;
      height: 65px;
      line-height: 64px;
      min-width: 220px;
    }

    @include media(slg) {
      border-radius: 29px;
      box-shadow: inset 0 0 0 8px white;
      font-size: 16px;
      height: 58px;
      line-height: 57px;
      min-width: 205px;
    }

    @include media(md) {
      border-radius: 26px;
      box-shadow: inset 0 0 0 6px white;
      height: 50px;
      line-height: 49px;
      min-width: 180px;
    }

    @include media(xmd) {
      border-radius: 24px;
      box-shadow: inset 0 0 0 5px white;
      font-size: 16px;
      height: 48px;
      line-height: 47px;
      min-width: 180px;
      padding: 0 15px;
    }

    @include media(sm) {
      border-radius: 21px;
      box-shadow: inset 0 0 0 4px white;
      height: 42px;
      line-height: 41px;
      min-width: 150px;
      padding: 0 10px;
    }
  }

  /* Стили кнопки по-умолчанию */
  display: inline-block;
  color: $buttonColor;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  height: 37px;
  line-height: 36px;
  padding: 0 20px;
  pointer-events: all;
  min-width: 120px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease-out;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
  -webkit-appearance: none;
  
  @content;
}



/* Инпуты  */
$isInputBorder: true;                    /* Есть ли рамка */
$isInputShadow: false;                   /* Есть ли тень  */

$inputColor: #7A7A7A;                    /* Цвет текста   */
$inputBg: #fff;                          /* Цвет фона     */
$inputBorderColor: #cacaca;              /* Цвет рамки    */
$inputShadow: inset 0 2px 7px 0 #a6a6a7; /* Тень          */


@mixin input() {
  background: $inputBg;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  color: $inputColor;
  display: block;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  height: 50px;
  padding: 6px 26px 6px 18px;
  outline: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @content;
}


/* Модули */
$moduleBg: #fff;                            /* Цвет фона модулей                      */
$moduleMobileHeaderBg: #c1c1c1;             /* Цвет фона заголовков модулей в мобайл  */
$moduleHeaderBg: $linkColor;                /* Цвет фона модулей                      */
$moduleHeaderColor: #fff;                   /* Цвет текста в шапке                    */
$moduleMobileHeaderColor: #2c2c2c;          /* Цвет текста в шапке                    */
$moduleBorder: 0;                           /* Рамка                                  */
$module_top__box-shadow: 0px 2px 3px #808080;

@mixin module($isMobile) {

  @if($isMobile == false) {
    background: $moduleBg;
  }
  
  margin: 1em 0 2em;

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }


  @content;
}



/*********************************************/
/* Горизонтальное меню */
$isTopMenuGradient: false;              /* Градиент да/нет? Если нет, используется цвет 1 */
$isTopMenuShadow: false;                /* Тень, да/нет          */
$isTopMenuBorder: false;                /* Рамка, да/нет         */
$isTopMenuBorderRounded: false;         /* Закругления, да/нет   */

$topMenuBgColor1: #fff;              /* Цвет фона 1 (нижний)  */
$topMenuBgColor2: #fff;              /* Цвет фона 2 (верхний) */
$topMenuBorder: 1px solid #aaa;         /* Рамка                 */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;  /* Тень у кнопок         */
$topMenuColor: #fff;                    /* Цвет текста           */
$topMenuBorderRadius: 0;                /* Закругления           */
$topMenuColor--hover: #edac0a;          /* Цвет текста на ховер  */

$topMenuBgColor1--selected: #fff;    /* Цвет фон 1 на текущий пункт */
$topMenuBgColor2--selected: #fff;    /* Цвет фон 2 на текущий пункт */

@mixin navtop() {

  @content;
}


@mixin fontawesome($n) {

  &:before {
    @if($n != '') {
      content: $n;
    }
    @else {
      content: '';
    }

    color: $linkColor;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;
    z-index: 2;

    @content;
  }
}



@mixin sideBorder($side: 'left') {
  &:after {
    background: $headerTopBgColorMobile * 1.2;
    border-right: 1px solid $headerTopBgColorMobile * 0.8;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    #{$side}: 0;
    width: 1px;

    @content;
  }
}


/* Товар */
$prodTitleColor: $linkColor;
$prodTextColor: $textColor;
$prodSecondColor: #8f8f8f;
$prodCurrentPriceColor: $black;
$prodOldPriceColor: #a4a4a4;



.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

.h1title {
  font-weight: bold;
  font-size: 47px;
  line-height: 55px;
  color: #444;
}

.h1catpg {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #000;
  text-align: left;
  text-transform: none;
}